import { createContext, useContext } from "react";
import { HydrateAtoms } from "~/components";
import { rootStore, supabaseAtom } from "~/stores";
import type { TypesafeClient } from "~/types";

interface SupabaseContextProps {
  supabase: TypesafeClient;
}

const SupabaseContext = createContext<SupabaseContextProps>(
  null as unknown as SupabaseContextProps
);

export function SupabaseProvider({
  supabase,
  children,
}: {
  supabase: TypesafeClient;
  children: React.ReactNode;
}) {
  return (
    <HydrateAtoms
      initialValues={[[supabaseAtom, supabase]]}
      options={{ store: rootStore }}
    >
      <SupabaseContext.Provider value={{ supabase }}>
        {children}
      </SupabaseContext.Provider>
    </HydrateAtoms>
  );
}

export function useSupabase() {
  const { supabase } = useContext(SupabaseContext);
  return supabase;
}
